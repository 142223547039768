
import SchoolNetworkCard from "@/components/business/network/SchoolNetworkCard"

export default {
    name: "SchoolNetworkList",
    components: {
        SchoolNetworkCard,
    },
    props: {
        schools: {
            type: Array,
            required: true,
        },
        schoolRequests: {
            type: Array,
            required: true,
        },
    },
    computed: {
        showNestedColumns() {
            return (
                this.schoolRequests.length % 2 === 1 && this.schools.length > 1
            )
        },
        schoolsList() {
            if (this.showNestedColumns) {
                return this.schools.slice(2)
            }
            return this.schools
        },
    },
}
