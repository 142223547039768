
import OImage from "@/components/base/image/OImage"
import Button from "@/components/base/Button"
import { mdiImageOff } from "@mdi/js"
import { routeToChat } from "@/helpers/routing"
export default {
    name: "SchoolNetworkCard",
    components: { OImage, Button },
    props: {
        school: {
            type: Object,
            required: true,
        },
        flex: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            mdiImageOff: mdiImageOff,
        }
    },
    inject: ["acceptRequest", "declineRequest"],
    computed: {
        mainImageSrc() {
            return this.$getImage({
                image: this.school.main_image,
                preferedSize: "3",
            }).src
        },
        isRequest() {
            return (
                this.school.accepted_at === null &&
                this.school.declined_at === null
            )
        },
        isSelfRequested() {
            return this.school.sent_by_type == "business"
        },
    },
    methods: {
        goToChat() {
            return this.$router.push(routeToChat(this.school.user_id))
        },
        onCardClick() {
            if (this.isRequest) {
                return this.goToChat()
            }
        },
    },
}
